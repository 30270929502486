ptz-dropdown-menu {
  .ptz-menu {
    position: relative;
    z-index: 1;

    &-boxed,
    &-lined {
      box-sizing: border-box;
      box-shadow: $petz-shadow-md;
      border-radius: $petz-rounded-md;
      background-color: $petz-color-neutral-white;
      padding: $petz-spacing-sm 0;
      width: 325px;
      margin: 1px;

      &-list {
        margin: 0;
        padding: 0;
        width: 325px;
        max-height: 300px;
        overflow: auto;
      }
    }
  }

  .srs-menu {
    position: relative;
    z-index: 1;

    &-boxed,
    &-lined {
      box-sizing: border-box;
      box-shadow: $seres-shadow-md;
      border-radius: $seres-rounded-md;
      background-color: $seres-color-neutral-white;
      padding: $seres-spacing-sm 0;
      width: 320px;
      margin: 1px;

      &-list {
        margin: 0;
        padding: 0;
        width: 320px;
        max-height: 300px;
        overflow: auto;
      }
    }

    &-boxed {
      width: 320px;

      &-list {
        width: 320px;
      }
    }

    &-lined {
      width: 310px;

      &-list {
        width: 310px;
      }
    }
  }
}
