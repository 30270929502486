import { Component, h, Host, Prop, State } from '@stencil/core';
import { currentId } from '../../utils/id';
import { PTZBrandConfig } from '../ptz-brand/types/ptz-brand.enums';
import { PTZBrandTypes } from '../ptz-brand/types/ptz-brand.types';
import { PTZIconTypes } from '../ptz-icon/types/ptz-icon.types';
import { PTZInputConfig } from './types/ptz-input.enums';
import { PTZInputTypes } from './types/ptz-input.types';
import { PTZIconConfig } from '../ptz-icon/types/ptz-icon.enums';
@Component({
  tag: 'ptz-input',
  styleUrl: 'ptz-input.scss',
  shadow: false
})
export class PTZInput {
  /** Define se o Input vai ter o autocomplete padrão */
  @Prop() autocomplete?: PTZInputTypes.AutoComplete = PTZInputConfig.AutoComplete.Off

  /** Define o estilo da marca do Input */
  @Prop() brand?: PTZBrandTypes.Name = PTZBrandConfig.Name.Petz

  /** Estado de renderização */
  @Prop() skeleton: boolean = false

  /** Botão para limpar o Input */
  @Prop() buttonClear?: boolean = false

  @State() currentValue: string = ''

  /** Alinhamento do ícone no Input */
  @Prop() iconAlign?: PTZIconTypes.Align = PTZInputConfig.IconAlign.Left

  /** Define o estilo da marca do Input */
  @Prop() iconInformation?: boolean = false

  /** Cor do ícone no Input */
  @Prop() iconColor?: PTZIconTypes.Color = PTZIconConfig.Color.NeutralDark

  /** Nome do ícone no Input */
  @Prop() iconName?: PTZIconTypes.Name

  /** Variante do ícone no Input */
  @Prop() iconVariant?: PTZIconTypes.Variant

  /** Preenche id padrão do Input */
  @Prop() inputId?: string

  /** Atributo onblur padrão do Input */
  @Prop() inputOnBlur?: (event: FocusEvent) => void

  /** Preenche title padrão do Input */
  @Prop() inputTitle?: string

  /** Variante do Input */
  @Prop() kind?: PTZInputTypes.Kind = PTZInputConfig.Kind.Boxed

  /** Preenche label do Input */
  @Prop() label?: string

  /** maxLength padrão do Input */
  @Prop() maxLength?: number

  /** Preenche placeholder do Input */
  @Prop() placeholder?: string

  /** Preenche readonly padrão do Input */
  @Prop() readonly?: boolean = false

  /** Preenche required padrão do Input */
  @Prop() required?: boolean = false

  /** Preenche o label quando houver anotação de obrigatoriedade do input */
  @Prop() requirementLabel?: PTZInputTypes.RequirementLabel

  /** Tamanho do Input */
  @Prop() size?: PTZInputTypes.Size = PTZInputConfig.Size.Mid

  /** Estado do Input */
  @Prop() state?: PTZInputTypes.State = PTZInputConfig.State.Resting

  /** Mensagem de suporte do Input */
  @Prop() supportLabel?: string

  /** Habilitar spellcheck padrão do Input */
  @Prop() spellcheck: boolean = false

  /** Preenche type padrão do Input */
  @Prop() type?: string = 'text'

  /** Atributo name padrão do Input */
  @Prop() name: string

  /** Atributo value padrão do Input */
  @Prop() value: string

  render() {
    const currentInputId = currentId(this.inputId)
    const requirementLabelContainerStyleClass = {
      [`${this.brand}-input-label`]: true,
      [`${this.brand}-input-label-${this.state}`]: true,
      [`${this.brand}-input-label-skeleton`]: this.skeleton
    }
    const requiredLabelStyleClass = {
      [`${this.brand}-input-optional`]: this.requirementLabel === PTZInputConfig.RequirementLabel.Optional,
      [`${this.brand}-input-required`]: this.requirementLabel !== PTZInputConfig.RequirementLabel.Optional
    }

    const inputLabel =
    this.label &&
    (this.kind === PTZInputConfig.Kind.Boxed || this.brand === PTZBrandConfig.Name.Petz) ?
    (
        <label class={requirementLabelContainerStyleClass} htmlFor={this.inputId}>
          <span>{this.label}</span>
          {this.requirementLabel && (
            <span class={requiredLabelStyleClass}>
              {this.requirementLabel}
            </span>
          )}
          {this.iconInformation ? <ptz-icon class={`${this.brand}-input-icon-info`} name="info-circle" variant="line" size="3xs" /> : ''}
        </label>
    )
    : '';

    const inputClass = `
      ${this.brand}-input
      ${this.brand}-input-${this.size}
      ${
        this.brand === PTZBrandConfig.Name.Petz && this.iconName && this.iconVariant && this.iconAlign
          ? `ptz-input-with-icon-${this.iconAlign} ptz-input-with-icon-${this.size}`
          : 'ptz-input-without-icon'
      }
      ${this.iconName && this.iconVariant ? `${this.brand}-input-with-icon` : ''}
      ${this.brand}-input-${this.kind}
      ${this.brand}-input-${this.kind}-${this.size}
      ${this.brand}-input-${this.kind}-${this.state}
      ${this.brand}-input-${this.kind}-${this.state}${this.value?.length > 0 ? '-done' : ''}
    `;
    const supportLabelStyleClass = {
      [`${this.brand}-input-message`]: true,
      [`${this.brand}-input-message-error`]: this.state === PTZInputConfig.State.Error,
      [`${this.brand}-input-message-skeleton`]: this.skeleton
    }

    const inputLoader =
    !(this.buttonClear || this.iconName || this.iconVariant || this.skeleton) &&
    this.state === PTZInputConfig.State.Loading &&
    (
      <ptz-spinner
        size="md"
        class={`
          ${this.brand}-input-loader
          ${this.brand}-input-loader-${this.kind}-${this.size}
          ${this.supportLabel ? `${this.brand}-input-loader-support-label-${this.kind}-${this.size}` : ''}
        `}
      ></ptz-spinner>
    );

    const inputIcon =
      this.iconName &&
      this.iconVariant &&
      !this.skeleton &&
      (
        <ptz-icon
          class={`
            ${this.brand}-input-icon
            ${this.brand}-input-icon-${this.iconAlign}
            ${this.brand}-input-icon-${this.kind}-${this.size}
            ${this.supportLabel ? `${this.brand}-input-icon-support-label-${this.kind}-${this.size}` : ''}
          `}
          name={this.iconName}
          variant={this.iconVariant}
          size="lg"
          color={this.state === PTZInputConfig.State.Error ? PTZIconConfig.Color.SupRedMid :  this.iconColor}
        ></ptz-icon>
      );

    const inputButtonClear = !this.skeleton && this.buttonClear && this.brand === PTZBrandConfig.Name.Petz && (
      <a
        class={`
          ptz-input-icon
          ptz-input-icon-anchor
          ${this.iconAlign === 'left' ? 'ptz-input-icon-right-space' : `ptz-input-icon-left`}
          ${this.brand}-input-icon-${this.kind}-${this.size}
          ${this.supportLabel ? `ptz-input-icon-support-label-${this.kind}-${this.size}` : ''}
        `}
        onClick={() => {
          return ((document.getElementById(currentInputId) as HTMLInputElement).value = '');
        }}
      >
        <ptz-icon name="times-circle" variant="line" size="lg" color="neutral-dark"></ptz-icon>
      </a>
    );

    const skeleton = {
      ptz: { w: 327, h: 52 },
      srs: { w: 331, h: 48 }
    }

    return (
      <Host>
        {inputLabel}
        {this.skeleton ?
        <ptz-skeleton width={skeleton[this.brand].w} height={skeleton[this.brand].h} rounded="xs" /> :
        <input
          class={inputClass}
          name={this.name}
          title={this.inputTitle}
          autocomplete={`${this.autocomplete}`}
          disabled={this.state === PTZInputConfig.State.Disabled || this.state === PTZInputConfig.State.Loading}
          onBlur={this.inputOnBlur}
          spellcheck={this.spellcheck}
          type={this.type ? this.type : 'text'}
          placeholder={this.placeholder}
          readonly={this.readonly}
          required={this.required}
          id={currentInputId}
          value={this.value}
          maxLength={this.maxLength}
        />}
        {inputLoader}
        {inputIcon}
        {inputButtonClear}
        <slot />
        {this.supportLabel && (
          <span class={supportLabelStyleClass}>{this.supportLabel}</span>
        )}
      </Host>
    );
  }
}
