ptz-input {
  display: inline-flex;
  flex-direction: column;
  position: relative;

  .ptz-input {
    height: 2.25rem;
    box-sizing: content-box;
    font-weight: $petz-font-weight-regular;
    line-height: $petz-font-lineheight-lg;
    outline: none;
    user-select: none;

    &::-ms-clear, &::-ms-reveal {
      display: none;
    }

    &-sm {
      padding: 0 $petz-spacing-sm;
    }

    &-md {
      padding: 0.375rem $petz-spacing-sm;
    }

    &-lg {
      padding: calc($petz-spacing-xs - .125rem) $petz-spacing-sm;
    }

    &-xl {
      padding: calc($petz-spacing-md - .125rem) $petz-spacing-sm;
    }

    &-with-icon-left {
      width: 16.25rem;
      padding-right: 2.938rem;
    }

    &-with-icon-right {
      width: 16.25rem;
      padding-left: 2.938rem;
    }

    &-without-icon {
      width: 18.438rem;
      padding-left: 0.75rem;
    }

    &-message {
      text-align: end;
      color: $petz-color-neutral-black;
      font-weight: $petz-font-weight-bold;
      font-size: $petz-font-size-3xs;
      line-height: $petz-font-lineheight-lg;
      margin-top: $petz-spacing-4xs;

      &-error {
        color: $petz-color-sup-red-mid;
      }

      &-skeleton {
        visibility: hidden;
        user-select: none;
        pointer-events: none;
      }
    }

    &-optional {
      color: $petz-color-neutral-dark;
      opacity: $petz-opacity-xl;
    }

    &-required {
      color: $petz-color-neutral-dark;
    }

    &-icon {
      position: absolute;

      &-anchor {
        cursor: pointer;
      }
      &-right-space {
        right: 2.938rem;
      }

      &-left {
        right: 0.938rem;
      }

      &-right {
        left: 0.938rem;
      }

      &-boxed,
      &-lined {
        &-sm {
          bottom: 0.438rem;
        }

        &-md {
          bottom: 0.875rem;
        }

        &-lg {
          bottom: $petz-spacing-sm;
        }

        &-xl {
          bottom: 1.75rem;
        }
      }

      &-support-label {

        &-lined,
        &-boxed {
          &-sm {
            bottom: 1.563rem;
          }

          &-md {
            bottom: $petz-spacing-lg;
          }

          &-lg {
            bottom: 2.125rem;
          }

          &-xl {
            bottom: 2.938rem;
          }
        }
      }

      &-info {
        margin-left: $petz-spacing-2xs;
      }

      i {
        display: flex;
      }
    }

    &-loader {
      display: inline-block;
      position: absolute;
      right: 0.938rem;

      &-boxed,
      &-lined {
        &-sm {
          bottom: 0.313rem;
        }

        &-md {
          bottom: 0.625rem;
        }

        &-lg {
          bottom: 0.875rem;
        }

        &-xl {
          bottom: 1.625rem;
        }
      }

      &-support-label {

        &-lined,
        &-boxed {
          &-sm {
            bottom: 1.438rem;
          }

          &-md {
            bottom: 1.688rem;
          }

          &-lg {
            bottom: $petz-spacing-lg;
          }

          &-xl {
            bottom: 2.813rem;
          }
        }
      }

      i {
        display: flex;
      }
    }

    &-label {
      display: flex;
      align-items: center;

      span {
        font-weight: $petz-font-weight-bold;
        font-size: $petz-font-size-2xs;
        color: $petz-color-neutral-darker-accent;
        line-height: $petz-font-lineheight-lg;
        user-select: none;

        &:nth-child(2) {
          font-weight: $petz-font-weight-regular;
          margin-left: $petz-spacing-2xs;
        }
      }

      &-skeleton {
        visibility: hidden;
        user-select: none;
        pointer-events: none;
      }

      &-disabled {
        span {
          color: $petz-color-neutral-dark;
        }
      }
    }

    &[placeholder] {
      font-weight: $petz-font-weight-regular;
      line-height: $petz-font-lineheight-lg;
    }

    &-boxed,
    &-lined {
      background-color: $petz-color-neutral-white;

      &-disabled {
        cursor: not-allowed;
        background-color: $petz-color-neutral-lighter-accent;
        color: $petz-color-neutral-dark;
      }

      &-loading {
        cursor: wait;
        border-color: $petz-color-primary-brand;
        border-width: $petz-border-md;
        color: $petz-color-neutral-black;
      }

      &-resting {
        color: $petz-color-neutral-black;
        border-color: $petz-color-neutral-mid;

        &-done:not(:focus) {
          border-color: $petz-color-neutral-black;
        }

        &::placeholder {
          color: $petz-color-neutral-dark;
        }
      }

      &-error {
        border-color: $petz-color-sup-red-mid;
        border-width: $petz-border-md;

        &[placeholder],
        &::placeholder {
          color: $petz-color-sup-red-mid;
        }
      }

      &-auto-filled:-webkit-autofill,
      &-auto-filled:-webkit-autofill:hover,
      &-auto-filled:-webkit-autofill:focus {
        color: $petz-color-neutral-black;
        background-color: $petz-color-primary-brand;
        opacity: $petz-opacity-xs;
      }
    }

    &-boxed,
    &-lined {

      &-sm,
      &-md,
      &-lg {
        font-size: $petz-font-size-2xs;

        &[placeholder],
        &::placeholder {
          font-size: $petz-font-size-2xs;
        }
      }

      &-xl {
        font-size: $petz-font-size-sm;

        &[placeholder],
        &::placeholder {
          font-size: $petz-font-size-sm;
        }
      }

      &-sm,
      &-md,
      &-lg,
      &-xl {
        border-style: solid;
        border-radius: $petz-rounded-xs;
        margin: 0.063rem;


        &:not(.ptz-input-boxed-loading, &.ptz-input-boxed-error) {
          border-width: $petz-border-sm;
        }

        &:focus {
          border-color: $petz-color-primary-brand;
          border-width: $petz-border-md;
        }
      }
    }
  }

  .srs-input {
    height: 2.25rem;
    font-weight: $seres-font-weight-regular;
    line-height: $seres-font-lineheight-sm;
    outline: none;
    user-select: none;

    &::-ms-clear, &::-ms-reveal {
      display: none;
    }

    &-sm {
      padding: 0 $seres-spacing-sm;
    }

    &-md {
      padding: $seres-spacing-3xs $seres-spacing-sm;
    }

    &-lg {
      padding: calc($seres-spacing-xs - .125rem) $seres-spacing-sm;
    }

    &-xl {
      padding: calc($seres-spacing-md - .125rem) $seres-spacing-sm;
    }

    &-with-icon-left {
      width: 16.25rem;
      padding-right: 2.813rem;
    }

    &-with-icon-right {
      width: 16.25rem;
      padding-left: 2.813rem;
    }

    &-without-icon {
      width: 18.438rem;
      padding-left: 0.75rem;
    }

    &-message {
      text-align: end;
      color: $seres-color-neutral-black;
      font-weight: $seres-font-weight-bold;
      font-size: $seres-font-size-3xs;
      line-height: $seres-font-lineheight-sm;
      margin-top: 0.125rem;

      &-error {
        color: $seres-color-sup-red-mid;
      }

      &-skeleton {
        visibility: hidden;
        user-select: none;
        pointer-events: none;
      }
    }

    &-optional {
      color: $seres-color-neutral-dark;
      opacity: $seres-opacity-xl;
    }

    &-required {
      color: $seres-color-neutral-dark;
    }

    &-icon {
      position: absolute;

      &-anchor {
        cursor: pointer;
      }

      &-left {
        right: 0.938rem;
      }

      &-right {
        left: 0.938rem;
      }

      &-boxed,
      &-lined {
        &-sm {
          bottom: 0.438rem;
        }

        &-md {
          bottom: 0.75rem;
        }

        &-lg {
          bottom: $seres-spacing-sm;
        }

        &-xl {
          bottom: 1.75rem;
        }
      }

      &-support-label {

        &-lined,
        &-boxed {
          &-sm {
            bottom: 1.563rem;
          }

          &-md {
            bottom: 1.875rem;
          }

          &-lg {
            bottom: 2.125rem;
          }

          &-xl {
            bottom: 2.938rem;
          }
        }
      }

      &-info {
        margin-left: $seres-spacing-2xs;
      }

      i {
        display: flex;
      }
    }

    &-loader {
      display: inline-block;
      position: absolute;
      right: 0.938rem;

      &-boxed,
      &-lined {
        &-sm {
          bottom: 0.313rem;
        }

        &-md {
          bottom: 0.625rem;
        }

        &-lg {
          bottom: 0.875rem;
        }

        &-xl {
          bottom: 1.625rem;
        }
      }

      &-support-label {

        &-lined,
        &-boxed {
          &-sm {
            bottom: 1.438rem;
          }

          &-md {
            bottom: 1.688rem;
          }

          &-lg {
            bottom: $seres-spacing-lg;
          }

          &-xl {
            bottom: 2.813rem;
          }
        }
      }

      i {
        display: flex;
      }
    }

    &-label {
      display: flex;
      align-items: center;

      span {
        font-weight: $seres-font-weight-bold;
        font-size: $seres-font-size-2xs;
        color: $seres-color-neutral-darker-accent;
        line-height: $seres-font-lineheight-sm;
        user-select: none;

        &:nth-child(2) {
          font-weight: $seres-font-weight-regular;
          margin-left: $seres-spacing-2xs;
        }
      }

      &-skeleton {
        visibility: hidden;
        user-select: none;
        pointer-events: none;
      }

      &-disabled {
        span {
          color: $seres-color-neutral-dark;
        }
      }
    }

    &[placeholder] {
      font-weight: $seres-font-weight-regular;
      line-height: $seres-font-lineheight-sm;
    }

    &-boxed,
    &-lined {
      background-color: $seres-color-neutral-white;

      &-disabled {
        cursor: not-allowed;
        background-color: $seres-color-neutral-lighter-accent;
        color: $seres-color-neutral-dark;
      }

      &-loading {
        cursor: wait;
        border-color: $seres-color-primary-brand;
        border-width: $seres-border-md;
        color: $seres-color-neutral-black;
      }

      &-resting {
        border-color: $seres-color-neutral-dark;

        &[placeholder],
        &::placeholder {
          color: $seres-color-neutral-dark;
        }
      }

      &-error {
        border-color: $seres-color-sup-red-mid;
        border-width: $seres-border-md;

        &[placeholder],
        &::placeholder {
          color: $seres-color-sup-red-mid;
        }
      }

      &-auto-filled:-webkit-autofill,
      &-auto-filled:-webkit-autofill:hover,
      &-auto-filled:-webkit-autofill:focus {
        background-color: $seres-color-primary-brand;
        opacity: $seres-opacity-xs;
      }
    }

    &-boxed,
    &-lined {

      &-sm,
      &-md,
      &-lg {
        font-size: $seres-font-size-2xs;

        &[placeholder],
        &::placeholder {
          font-size: $seres-font-size-2xs;
        }
      }

      &-xl {
        font-size: $seres-font-size-sm;

        &[placeholder],
        &::placeholder {
          font-size: $seres-font-size-sm;
        }
      }

      &-sm,
      &-md,
      &-lg,
      &-xl {
        border-style: solid;
        border-radius: $seres-rounded-xs;
        margin: 0.063rem;


        &:not(.ptz-input-boxed-loading, &.srs-input-boxed-error) {
          border-width: $seres-border-sm;
        }

        &:focus {
          border-color: $seres-color-primary-brand;
          border-width: $seres-border-md;
        }
      }
    }
  }
}
