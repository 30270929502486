import { EnumToArray } from '../../../utils/enumToArray';
import { PTZBrandConfig, PTZBrandConfigList } from '../../ptz-brand/types/ptz-brand.enums';

enum Kind {
  Boxed = 'boxed',
  Lined = 'lined',
}

export const PTZDropdownMenuConfigList = {
  Brand: PTZBrandConfigList.Name,
  Kind: EnumToArray([Kind]),
};

export const PTZDropdownMenuConfig = {
  Brand: PTZBrandConfig.Name,
  Kind,
};
