import { Component, h, Host, Prop } from '@stencil/core';
import { PTZBrandConfig } from '../ptz-brand/types/ptz-brand.enums';
import { PTZBrandTypes } from '../ptz-brand/types/ptz-brand.types';
import { PTZDropdownMenuConfig } from './types/ptz-dropdown-menu.enums';
import { PTZDropdownMenuTypes } from './types/ptz-dropdown-menu.types';

@Component({
  tag: 'ptz-dropdown-menu',
  styleUrl: 'ptz-dropdown-menu.scss',
  shadow: false
})

export class PTZDropdownMenu {
  /** Define o estilo da marca do DropdownMenu */
  @Prop() brand?: PTZBrandTypes.Name = PTZBrandConfig.Name.Petz

  /** Variante do Dropdown */
  @Prop() kind: PTZDropdownMenuTypes.Kind = PTZDropdownMenuConfig.Kind.Boxed

  render() {
    return (
      <Host>
        <div class={`${this.brand}-menu ${this.brand}-menu-${this.kind}`}>
          <ul class={`${this.brand}-menu-${this.kind}-list`}>
            <slot></slot>
          </ul>
        </div>
      </Host>
    );
  }
}